import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { toMoney } from '../../Utils/Utils'
import 'moment/locale/pt-br'

import ButtonDropdown from 'src/UIComponents/ButtonDropdown/ButtonDropdown'

import { Range } from './Range'
import Icon from 'src/UIComponents/Icon/Icon'

import { lightGray, smallMargin } from 'src/Styles/settings/Constants'

moment.locale('pt-br')

const RangeSelector = ({ placeholder, value, onClear, onSubmit }) => {
  const [label, setLabel] = useState()
  const [range, setRange] = useState(value)
  const [selected, setSelected] = useState(value)

  const handleChange = (value) => {
    setRange(value)
  }

  const handleSubmit = () => {
    setSelected(range)
    onSubmit(range)
  }

  const handleClear = () => {
    setRange()
    setSelected()
    onClear()
  }

  const handleLabel = (range) => {
    const from = range ? range.gte : undefined
    const to = range ? range.lte : undefined

    const label =
      from && to ? (
        <>
          {toMoney(from / 100)}
          <Icon src='/assets/icons/arrow/mini-arrow-right.svg' /> {toMoney(to / 100)}
        </>
      ) : (
        (from || to) && toMoney((from || to) / 100)
      )
    setLabel(label)
  }

  useEffect(() => {
    handleLabel(selected)
  }, [selected])

  useEffect(() => {
    setRange(value)
    setSelected(value)
  }, [value])

  const component = () => (
    <StyledRange>
      <Range type='money' value={range} onChange={handleChange} />
    </StyledRange>
  )

  return (
    <Wrapper>
      <ButtonDropdown value={selected} placeholder={placeholder} label={label} options={[{ component }]} onClear={handleClear} onSubmit={handleSubmit} />
    </Wrapper>
  )
}

RangeSelector.propTypes = {
  onClear: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object
}

RangeSelector.defaultProps = {
  onClear: () => {
    /* noop */
  },
  onSubmit: () => {
    /* noop */
  }
}

const StyledRange = styled.div`
  padding: 0 ${smallMargin};
  margin: 1rem 0;

  & > div {
    margin: 1rem 0;
  }

  & input {
    background-color: ${lightGray};
    width: 100%;
    border-radius: 3px;
    border: 0;

    :focus {
      outline: unset;
    }
  }
`

const Wrapper = styled.div`
  .dropdown-menu {
    min-width: 180px;
  }
`

export { RangeSelector }
