import React from 'react'
import PropTypes from 'prop-types'
import ButtonDefault from 'src/UIComponents/Button/ButtonDefault'

const ActivateButton = ({
  onClick,
  ...props
}) => (
  <ButtonDefault {...props} icon='/assets/icons/system/check.svg' widthAuto outline ghost onClick={onClick}>
    Ativar
  </ButtonDefault>
)

ActivateButton.propTypes = {
  onClick: PropTypes.func
}

export default ActivateButton
