import React from 'react'
import * as PropTypes from 'prop-types'

import Grid from 'src/UIComponents/Grid/Grid'

import { SaleActions } from '../../../Components/SaleDetail/SaleActions'

const SaleInfoNupay = ({ sale }) => {
  return (
    <Grid noPadding marginBottom={'30px'}>
      <Grid.Row auto>
        <SaleActions sale={sale} />
      </Grid.Row>
    </Grid>
  )
}

SaleInfoNupay.propTypes = {
  sale: PropTypes.object
}

export default SaleInfoNupay
