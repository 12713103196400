import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { parseDate, toMoney } from 'src/Utils/Utils'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import { getStatusLabel } from 'src/Constants/StatusLabels'
import HistoryLoader from 'src/UIComponents/SaleDetail/History/HistoryLoader'
import { smallMargin, smallText, bigPadding, gray, normalText, lightGray, darkGray, red } from '../../../Styles/settings/Constants'

const History = ({ sale, requesting }) => {
  const itemHistory = () => {
    return sale.history.map((item, index) => {
      let status = item.operation_type

      if (item.status &&
          item.status !== '' &&
          (item.operation_type === 'authorization' ||
            item.operation_type === 'void' ||
            item.operation_type === 'void_partial' ||
            item.operation_type === 'void_confirmation' ||
            item.operation_type === 'capture' ||
            item.operation_type === 'risk_analisys' ||
            item.operation_type === 'pre_authorization' ||
            item.operation_type === 'refund'
          )) {
        status = (item.operation_type + '_' + item.status)
      }

      let label = getStatusLabel(status).label || item.status
      let color = getStatusLabel(status).color || gray
      const showARNLabel = item.operation_type === 'void' && sale.payment_type === 'pix' && item.authorization_code

      if (label === 'Cancelamento total') {
        if (sale.amount === item.amount) {
          label = 'Cancelamento total'
          color = red
        } else {
          label = 'Cancelamento parcial'
          color = red
        }
      }

      return (
        <ItemContainer key={index}>
          <Grid.Row smallMargin>
            <Line />
            <Grid.Col cols={1}>
              <Bullet data-tracking={label} color={getStatusLabel(status).color || gray} isFirst={index === 0} />
            </Grid.Col>
            <Grid.Col cols={6}>
              <Label data-tracking={label} color={darkGray}>{parseDate(`${item.created_at}+00:00`, 'DD/MM/YYYY HH:mm:ss')}</Label>
            </Grid.Col>
            <Grid.Col cols={5}>
              <Label
                color={color}
                bold
                uppercase
              >
                {label}
              </Label>
            </Grid.Col>
            <Grid.Col cols={4}>
              <Label color={darkGray}>
                {toMoney(item.amount)}
              </Label>
            </Grid.Col>
          </Grid.Row>
          {showARNLabel && <ARNLabel>
            Código ARN: {item.authorization_code}
          </ARNLabel>}
        </ItemContainer>
      )
    })
  }

  const listHistory = () => {
    return (
      <Content>
        <Grid noPadding>
          <Grid.Row noMargin>
            <Grid.Col cols={6} offset={1}>
              <Label color={darkGray}>Data</Label>
            </Grid.Col>
            <Grid.Col cols={5}>
              <Label color={darkGray}>Status</Label>
            </Grid.Col>
            <Grid.Col cols={4}>
              <Label color={darkGray}>Valor</Label>
            </Grid.Col>
          </Grid.Row>
          <>
            {itemHistory()}
          </>
        </Grid>
      </Content>
    )
  }

  return (
    <UIHistory>
      <Grid noPadding>
        {requesting && (
          <HistoryLoader />
        )}
        {sale && !requesting && (
          <Fragment>
            <Grid.Row>
              <Grid.Col cols={14} offset={1}>
                <Label
                  fontSize={normalText}
                  color={gray}
                  data-test='transaction-detailSale-history-message'
                >
                Acompanhe todo o histórico de status da sua venda, desde sua criação até o momento
                </Label>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col cols={14} offset={1}>
                {listHistory()}
              </Grid.Col>
            </Grid.Row>
          </Fragment>
        )}
      </Grid>
    </UIHistory>
  )
}

History.propTypes = {
  sale: PropTypes.object,
  requesting: PropTypes.bool
}

const UIHistory = styled.div`
  color: ${gray};
`

const Bullet = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.color};
  border-radius: 3px;
  margin: auto;
  margin-top: 4px;
  position: absolute;

  &:after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    width: 30%;
    height: 30%;
    background: ${props => props.isFirst ? '#fff' : 'transparent'};
  }
`

const Content = styled.div`
  position: relative;
  margin-bottom: ${bigPadding};
`

const Line = styled.div`
  width: 1px;
  height: calc(100% - 30px);
  background-color: ${lightGray};
  position: absolute;
  top: 25px;
  left: 4.5px;
  z-index: -1;
`

const ItemContainer = styled.div`
  margin-bottom: ${smallMargin};
`

const ARNLabel = styled.div`
  font-size: ${smallText};
  margin-top: -1rem;
`

export default History
