import * as PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'
import Title from 'src/UIComponents/Title/Title'
import { formatAddress } from 'src/Utils/Utils'

import {
  bigPadding,
  darkGray,
  darkerGray,
  normalText,
  smallerText
} from 'src/Styles/settings/Constants'

const SaleInfoSeller = ({ sale }) => {
  let sellerInfo = {
    name: '',
    cpfCnpj: '',
    id: '',
    address: ''
  }

  if (sale.individual) {
    const { address } = sale.individual
    sellerInfo = {
      name: `${sale.individual.first_name} ${sale.individual.last_name}`,
      cpfCnpj: sale.individual.taxpayer_id.toCPF(),
      id: sale.individual.id,
      address: formatAddress(address)
    }
  } else if (sale.business) {
    const { business_address: businessAddress } = sale.business
    sellerInfo = {
      name: `${sale.business.business_name}`,
      cpfCnpj: sale.business.ein?.toCnpj(),
      id: sale.business.id,
      address: formatAddress(businessAddress)
    }
  }
  return (
    <Grid noPadding>
      <Grid.Row auto>
        <Title noTransform noPadding small line>
          <strong>Dados</strong> do Vendedor
        </Title>
      </Grid.Row>
      <ContentWrapper>
        <Grid.Row>
          <Grid.Col cols={9}>
            <Grid.Row noMargin auto>
              <Label fontSize={smallerText} color={darkGray}>
                Nome do Vendedor
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label overflow color={darkerGray} fontSize={normalText}>
                {sellerInfo.name}
              </Label>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col cols={7}>
            <Grid.Row noMargin auto>
              <Label fontSize={smallerText} color={darkGray}>
                {sale.business ? 'CNPJ' : 'CPF'}
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label color={darkerGray} fontSize={normalText}>
                {sellerInfo.cpfCnpj}
              </Label>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row auto noMargin>
          <Label fontSize={smallerText} color={darkGray}>
            ID do Vendedor:
          </Label>
        </Grid.Row>
        <Grid.Row auto>
          {sellerInfo.id ? (
            <LabelCopyToClipboard
              color={darkerGray}
              size='normal'
              text={sellerInfo.id}
              hoverText
            >
              {sellerInfo.id}
            </LabelCopyToClipboard>
          ) : (
            <Label color={darkerGray}>N/A</Label>
          )}
        </Grid.Row>
        <Grid.Row auto noMargin>
          <Label fontSize={smallerText} color={darkGray}>
            Endereço:
          </Label>
        </Grid.Row>
        <Grid.Row auto>
          <Label color={darkerGray} fontSize={normalText}>
            {sellerInfo.address}
          </Label>
        </Grid.Row>
      </ContentWrapper>
      <br />
    </Grid>
  )
}

SaleInfoSeller.propTypes = {
  sale: PropTypes.object
}

export default SaleInfoSeller

const ContentWrapper = styled.div`
  padding-left: ${bigPadding};
`
