import React from 'react'
import PropTypes from 'prop-types'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import ActivateButton from 'src/UIComponents/RecipientMeanListItem/Common/ActivateButton'
import ButtonsWrapper from 'src/UIComponents/RecipientMeanListItem/Common/ButtonsWrapper'
import StyledRow from 'src/UIComponents/RecipientMeanListItem/Common/StyledRow'
import StyledCol from 'src/UIComponents/RecipientMeanListItem/Common/StyledCol'
import TableEditCell from 'src/UIComponents/TableEditCell/TableEditCell'

import {
  darkGray,
  normalText,
  smallerText
} from 'src/Styles/settings/Constants'
import Dots from 'src/UIComponents/Dots/Dots'
import styled from 'styled-components'

const DigitalAccountListItem = ({
  digitalAccount: { name, taxpayerId },
  submitDialogActive,
  onOpenSubmitDialog,
  onSubmit,
  onCloseDialog,
  disableActions,
  isLoading,
  hasActions,
  ...props
}) => (
  <StyledRow noMargin active={submitDialogActive}>
    <StyledCol cols={!hasActions ? 5 : 6}>
      <Grid.Row auto noMargin>
        <Label fontSize={smallerText} color={darkGray}>
          Titular
        </Label>
      </Grid.Row>
      <Grid.Row auto>
        <Label fontSize={normalText} color={darkGray} bold>
          {name}
        </Label>
      </Grid.Row>
    </StyledCol>
    <StyledCol cols={!hasActions ? 5 : 4}>
      <Grid.Row auto noMargin>
        <Label fontSize={smallerText} color={darkGray}>
          CPF
        </Label>
      </Grid.Row>
      <Grid.Row auto>
        <Label fontSize={normalText} color={darkGray} bold>
          {taxpayerId && taxpayerId?.toDocument()}
        </Label>
      </Grid.Row>
    </StyledCol>
    <StyledCol cols={submitDialogActive && !isLoading ? 2 : 3} />
    {hasActions && !isLoading && (
      <StyledCol cols={submitDialogActive ? 4 : 3} active={submitDialogActive}>
        {!submitDialogActive && (
          <ButtonsWrapper>
            <ActivateButton
              data-tracking={`${props['data-tracking']} > Conta digital > Habilitar`}
              data-test='config-meiosRecebimento-ativar_contaDigital-btn'
              disabled={disableActions}
              onClick={() => {
                console.info('props > ', props['data-tracking'])
                onOpenSubmitDialog('digitalAccount')
              }}
            />
          </ButtonsWrapper>
        )}
        {submitDialogActive && (
          <TableEditCell
            action={() => {
              onSubmit()
            }}
            close={onCloseDialog}
            data-tracking={`${props['data-tracking']} > Conta digital > Habilitar > Ativar`}
            withAdjustPadding
          >
            Deseja <strong>ativar</strong> esta conta?
          </TableEditCell>
        )}
      </StyledCol>
    )}
    {isLoading && (
      <StyledCol cols={3}>
        <Container>
          <Dots outline />
        </Container>
      </StyledCol>
    )}
  </StyledRow>
)

DigitalAccountListItem.propTypes = {
  digitalAccount: PropTypes.object,
  hasActions: PropTypes.bool,
  submitDialogActive: PropTypes.bool,
  onOpenSubmitDialog: PropTypes.func,
  onSubmit: PropTypes.func,
  onCloseDialog: PropTypes.func,
  disableActions: PropTypes.bool,
  isLoading: PropTypes.bool,
  'data-tracking': PropTypes.string
}
const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export default DigitalAccountListItem
