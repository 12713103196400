import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Label from 'src/UIComponents/Input/Label'
import Input from 'src/UIComponents/Input/Input'
import FormField from 'src/UIComponents/Form/FormField'
import StyledCurrencyInput from 'src/UIComponents/CurrencyInput/CurrencyInputNegative'

import { gray } from 'src/Styles/settings/Constants'

const InputComponent = ({ type, value, ...props }) =>
  type === 'money' ? (
    <>
      <StyledCurrencyInput
        value={value ? value / 100 : value === 0 ? 0 : null}
        {...props}
      />
    </>
  ) : (
    <Input defaultValue={value} {...props} />
  )

const RangeNegative = ({ type, value, onChange }) => {
  const [state, setState] = useState(value || {})

  const containsNumbers = (string) => {
    // Regular expression to match an optional minus sign followed by any digit (0-9)
    const regex = /([0-9]+|-?[0-9])/
    // Test if the string contains at least one digit (positive or negative)
    return regex.test(string)
  }

  const handleChange = prop => e => {
    let value = e.target.value

    let cleanField = ''

    if (state?.[prop]) {
      cleanField = state?.[prop].toString()
    }

    if (value === '' || (cleanField.length === 2 && value === '-')) {
      value = ''
    } else {
      if (!containsNumbers(value)) {
        return
      }

      if (type === 'money') {
        value = parseInt(value.replace(/[^0-9|-]/g, ''))
      }
    }

    const valueToSet = { ...state, [prop]: value }

    if (value === '') {
      delete valueToSet[prop]
    }

    setState(valueToSet)
  }

  return (
    <Fragment>
      <FormField>
        <Label color={gray} align='right'>De: </Label>
        <InputComponent tabIndex={0} type={type} value={state.gte} onChange={handleChange('gte')} onBlur={() => onChange(state)} />
      </FormField>
      <FormField>
        <Label color={gray} align='right'>Até: </Label>
        <InputComponent tabIndex={0} type={type} value={state.lte} onChange={handleChange('lte')} onBlur={() => onChange(state)} />
      </FormField>
    </Fragment>
  )
}

RangeNegative.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

RangeNegative.defaultProps = {
  value: {}
}

export { RangeNegative }
