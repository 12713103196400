import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { getFormattedKeyType, getFormattedKey } from 'src/Utils/saleUtils'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Title from 'src/UIComponents/Title/Title'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'

import { smallerText, normalText, darkGray, darkerGray, bigPadding } from 'src/Styles/settings/Constants'
import { SaleActions } from '../../../Components/SaleDetail/SaleActions'
import { isAbleToCancel } from '../../../Utils/saleUtils'

const SaleInfoPix = ({ sale }) => {
  return (
    <Grid noPadding>
      {isAbleToCancel(sale) && (
        <Grid.Row auto>
          <SaleActions sale={sale} />
        </Grid.Row>
      )}
      {sale.payment_method && sale.payment_method.key && (
        <>
          <Grid.Row auto>
            <Title noTransform noPadding small line>
              <strong>Dados</strong> da Venda
            </Title>
          </Grid.Row>
          <ContentWrapper>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Chave
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label color={darkerGray} fontSize={normalText}>
                {getFormattedKey(
                  sale.payment_method.key.type.toLowerCase(),
                  sale.payment_method.key.value
                )}
              </Label>
            </Grid.Row>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Tipo de Chave
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label color={darkerGray} fontSize={normalText}>
                {getFormattedKeyType(sale.payment_method.key.type.toLowerCase())}
              </Label>
            </Grid.Row>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Provedor
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label color={darkerGray} fontSize={normalText}>
                {sale.payment_method.provider}
              </Label>
            </Grid.Row>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Tipo do Pix
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label color={darkerGray} fontSize={normalText}>
                {sale.payment_method.type === 'DYNAMIC' ? 'Dinâmico' : 'Estático'}
              </Label>
            </Grid.Row>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Versão do Pix
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label color={darkerGray} fontSize={normalText}>
                {sale.payment_method.version}
              </Label>
            </Grid.Row>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                ID do Pix
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              {sale.payment_method.id ? (
                <LabelCopyToClipboard
                  color={darkerGray}
                  size='normal'
                  text={sale.payment_method.id}
                >
                  {sale.payment_method.id}
                </LabelCopyToClipboard>
              ) : (
                <Label color={darkerGray}>N/A</Label>
              )}
            </Grid.Row>
            <br />
          </ContentWrapper>
          <br />
        </>
      )}
    </Grid>
  )
}

SaleInfoPix.propTypes = {
  sale: PropTypes.object
}

export default SaleInfoPix

const ContentWrapper = styled.div`
  padding-left: ${bigPadding};
`
