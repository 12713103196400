import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import { getTranslatedExportType } from 'src/Utils/Utils'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import Grid from 'src/UIComponents/Grid/Grid'
import AlertMessage from 'src/UIComponents/AlertMessage/AlertMessage'
import Button from 'src/UIComponents/Button/ButtonDefault'
import Label from 'src/UIComponents/Label/Label'
import SelectMultiply from 'src/UIComponents/SelectMultiplyWithGroup/SelectMultiply'
import { ExportColumnsOptions } from 'src/Constants/ExportColumnsOptions'

import { darkGray, normalText } from '../../Styles/settings/Constants'

const ReportCustomColumns = ({
  handleClose,
  type,
  error,
  handleSubmit
}) => {
  const [values, setValues] = useState(new Set())
  const [hasLocalError, setHasLocalError] = useState(false)
  const sizeLocalError = {
    message: {
      title: 'Ops, você esqueceu de selecionar as colunas!',
      message: 'Por favor, selecione as colunas que deseja exportar'
    }
  }

  const handleSetValues = values => {
    setHasLocalError(false)
    setValues(values)
  }

  const onSubmit = fileType => {
    if (values.size === 0) {
      return setHasLocalError(true)
    }
    setHasLocalError(false)
    return handleSubmit(type, fileType, values)
  }

  return (
    <Fragment>
      <HeaderSidebar
        uppercase
        title={<><strong>Exportar</strong> {getTranslatedExportType(type)}</>}
        handleClose={handleClose}
      />
      <SidebarContainer>
        <Grid.Col cols={16}>
          <Grid.Row auto>
            <Label color={darkGray} fontSize={normalText}>
              Selecione abaixo as colunas do relatório
            </Label>
          </Grid.Row>
          <Grid.Row auto data-tracking='Report > Custom > List'>
            <SelectMultiply
              onChange={handleSetValues}
              values={values}
              items={ExportColumnsOptions[type]}
              noLine
            />
          </Grid.Row>
          {(hasLocalError || error) &&
            <Grid.Row auto>
              <AlertMessage type='error' error={sizeLocalError || error} />
            </Grid.Row>
          }
          <Grid.Row data-tracking='Report > Custom > Export'>
            <Grid.Col cols={8}>
              <Button
                type='button'
                clean
                icon='/assets/icons/others/enterprise.svg'
                onClick={() => onSubmit('xlsx')}
                data-test='report-custom-xlsx-btn'
                iconTop
              >
                Exportar em .XLSX
              </Button>
            </Grid.Col>
            <Grid.Col cols={8}>
              <Button
                type='button'
                clean
                icon='/assets/icons/others/enterprise.svg'
                onClick={() => onSubmit('csv')}
                data-test='report-custom-csv-btn'
                iconTop
              >
                Exportar em .CSV
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
        <br />
      </SidebarContainer>
    </Fragment>
  )
}

ReportCustomColumns.propTypes = {
  handleClose: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.object,
  handleSubmit: PropTypes.func
}

export default ReportCustomColumns
