import React from 'react'
import PropTypes from 'prop-types'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import StyledRow from 'src/UIComponents/RecipientMeanListItem/Common/StyledRow'
import StyledCol from 'src/UIComponents/RecipientMeanListItem/Common/StyledCol'

import {
  darkGray,
  normalText,
  smallerText
} from 'src/Styles/settings/Constants'
import StyledLabel from 'src/UIComponents/RecipientMeanListItem/Common/StyledLabel'

const BankAccountActiveItem = ({
  bankAccount: {
    bank_name: bankName,
    bank_code: bankCode,
    type,
    routing_number: routingNumber,
    routing_check_digit: routingCheckDigit,
    account_number: accountNumber,
    holder_name: holderName,
    taxpayer_id: taxpayerId
  }
}) => {
  const accountTypeLabel =
    type === 'Checking' ? 'Conta Corrente' : 'Conta Poupança'
  const documentLabel = taxpayerId.length === 11 ? 'CPF' : 'CNPJ'

  return (
    <StyledRow noMargin>
      <StyledCol cols={3}>
        <Grid.Row auto noMargin>
          <Label fontSize={smallerText} color={darkGray}>
            Banco
          </Label>
        </Grid.Row>
        <Grid.Row auto>
          <StyledLabel fontSize={normalText} color={darkGray} bold>
            {`${bankCode} - ${bankName}`}
          </StyledLabel>
        </Grid.Row>
      </StyledCol>
      <StyledCol cols={3}>
        <Grid.Row>
          <Grid.Col cols={5}>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Agência
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <StyledLabel fontSize={normalText} color={darkGray} bold>
                {routingNumber}
              </StyledLabel>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col cols={3}>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Dígito
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <StyledLabel fontSize={normalText} color={darkGray} bold>
                {routingCheckDigit}
              </StyledLabel>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col cols={8}>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                {accountTypeLabel}
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <StyledLabel fontSize={normalText} color={darkGray} bold>
                {accountNumber}
              </StyledLabel>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </StyledCol>
      <StyledCol cols={2}>
        <Grid.Row auto noMargin>
          <Label fontSize={smallerText} color={darkGray}>
            Nome do favorecido
          </Label>
        </Grid.Row>
        <Grid.Row auto>
          <StyledLabel fontSize={normalText} color={darkGray} bold>
            {holderName}
          </StyledLabel>
        </Grid.Row>
      </StyledCol>
      <StyledCol cols={3}>
        <Grid.Row auto noMargin>
          <Label fontSize={smallerText} color={darkGray}>
            {documentLabel}
          </Label>
        </Grid.Row>
        <Grid.Row auto>
          <StyledLabel fontSize={normalText} color={darkGray} bold>
            {taxpayerId?.toDocument()}
          </StyledLabel>
        </Grid.Row>
      </StyledCol>
    </StyledRow>
  )
}

BankAccountActiveItem.propTypes = {
  bankAccount: PropTypes.object
}

export default BankAccountActiveItem
