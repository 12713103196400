import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import { getAuthorizerError } from 'src/Errors/gatewayAuthorizerErrors'
import { getPaymentTypeIcon, getPaymentType, toMoney } from 'src/Utils/Utils'
import { statusLabels } from 'src/Constants/StatusLabels'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import AlertMessage from 'src/UIComponents/AlertMessage/AlertMessage'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'

import {
  smallerText,
  bigText,
  darkGray,
  darkerGray
} from 'src/Styles/settings/Constants'
import Button from 'src/UIComponents/Button/ButtonDefault'

const SaleResume = ({ sale }) => {
  const { receivables } = useSelector(({ transactions }) => transactions)
  const { gateway_authorizer: gatewayAuthorizer, point_of_sale: { entry_mode: entryMode } } = sale

  const isBoleto = sale.payment_type === 'boleto'

  const saleHistorySortedByDate = sale.history.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
  const lastHistory = saleHistorySortedByDate.at(-1)
  const failed = lastHistory?.status === 'failed' ? lastHistory : null

  let error = null
  let responseCode = null
  if (failed) {
    responseCode = failed && failed.response_code
    const responseMessage = failed && failed.response_message
    error = getAuthorizerError(gatewayAuthorizer, entryMode, { responseCode, responseMessage })
    if (!error && responseCode) {
      error = {
        definition: '',
        action: responseMessage || ''
      }
    }
  }

  const totalAnticipationFee = receivables && receivables.reduce((acc, item) => acc + parseFloat(item.anticipation_fee), 0)
  const fees = parseFloat(sale.fees)
  const netAmount = parseFloat(sale.amount) - fees - totalAnticipationFee

  return (
    <SaleResumeContainer>
      <Grid noPadding>
        <StatusBar color={statusLabels[sale.status].color} />
        <Grid.Row auto noMargin>
          <Label color={statusLabels[sale.status].color} bold uppercase>
            {statusLabels[sale.status].label}
          </Label>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col cols={8}>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Valor da venda:
              </Label>
            </Grid.Row>
            <Grid.Row auto noMargin>
              <Label
                color={statusLabels[sale.status].color}
                bold
                uppercase
                fontSize={bigText}
              >
                {toMoney(sale.amount)}
              </Label>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col cols={8}>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Valor Líquido:
              </Label>
            </Grid.Row>
            <Grid.Row auto noMargin>
              <Label
                color={statusLabels[sale.status].color}
                bold
                uppercase
                fontSize={bigText}
              >
                {toMoney(netAmount)}
              </Label>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col cols={8}>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Taxa de Venda
              </Label>
            </Grid.Row>
            <Grid.Row auto noMargin>
              <Label color={darkerGray} fontSize={bigText}>
                {`${toMoney(fees)}`}
              </Label>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col cols={8}>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Taxa de Antecipação
              </Label>
            </Grid.Row>
            <Grid.Row auto noMargin>
              <Label color={darkerGray} fontSize={bigText}>
                {toMoney(totalAnticipationFee)}
              </Label>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row auto noMargin>
          <Label fontSize={smallerText} color={darkGray}>
            Tipo de Venda:
          </Label>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col cols={isBoleto ? 6 : 10}>
            <Label color={darkerGray} fontSize={bigText}>
              {getPaymentType(sale.payment_type, sale.installment_plan)}
            </Label>
          </Grid.Col>
          {isBoleto && (
            <ColRelative cols={4}>
              <ButtonWrapper>
                <StyledButton link onClick={() => window.open(sale.payment_method.url, '_blank')} data-test='transaction-detail-boleto-btn'>
                  Ver Boleto
                </StyledButton>
              </ButtonWrapper>
            </ColRelative>
          )}
          <ColRelative cols={5}>
            <SVG src={getPaymentTypeIcon(sale.payment_type)} />
          </ColRelative>
        </Grid.Row>
        <Grid.Row noMargin auto>
          <Label fontSize={smallerText} color={darkGray}>
            ID da Transação:
          </Label>
        </Grid.Row>
        <Grid.Row auto>
          {sale.id ? (
            <LabelCopyToClipboard
              color={darkerGray}
              text={sale.id}
              size='big'
              hoverText
            >
              {' ' + sale.id}
            </LabelCopyToClipboard>
          ) : (
            <Label color={darkGray}>N/A</Label>
          )}
        </Grid.Row>
        {sale.reference_id ? (
          <Fragment>
            <Grid.Row noMargin auto>
              <Label fontSize={smallerText} color={darkGray}>
                ID de Referência:
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <LabelCopyToClipboard
                color={darkerGray}
                text={sale.reference_id}
                size='big'
                hoverText
              >
                {' ' + sale.reference_id}
              </LabelCopyToClipboard>
            </Grid.Row>
          </Fragment>
        ) : (
          null
        )}
        {
          error && (
            <Grid.Row auto>
              <AlertMessage
                type='error'
                error={{
                  message: {
                    title: `ERRO ${responseCode}. ${error.definition}`,
                    message: error.action
                  }
                }} />
            </Grid.Row>
          )
        }
      </Grid>
    </SaleResumeContainer>
  )
}

SaleResume.propTypes = {
  sale: PropTypes.object
}

const SaleResumeContainer = styled.div``

const StatusBar = styled.div`
  width: 6px;
  height: 55px;
  background-color: ${(props) => props.color};
  position: absolute;
  left: 0px;
`

const ColRelative = styled(Grid.Col)`
  position: relative;
`

const SVG = styled(ReactSVG)`
  display: flex;
  align-items: flex-end;
  height: 100%;
  position: absolute;

  svg {
    height: 3.1rem;
    width: 3.1rem;
  }
`

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: absolute;
`

const StyledButton = styled(Button)`
  margin-bottom: 0.65rem;
  padding: 0;
`

export default SaleResume
