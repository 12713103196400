import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Label from 'src/UIComponents/Input/Label'
import Input from 'src/UIComponents/Input/Input'
import FormField from 'src/UIComponents/Form/FormField'
import StyledCurrencyInput from 'src/UIComponents/CurrencyInput/CurrencyInput'

import { gray } from 'src/Styles/settings/Constants'

const InputComponent = ({ type, value, ...props }) =>
  type === 'money' ? (
    <StyledCurrencyInput
      value={value ? value / 100 : null}
      {...props}
    />
  ) : (
    <Input defaultValue={value} {...props} />
  )

const Range = ({ type, value, onChange }) => {
  const [state, setState] = useState(value || {})

  const handleChange = prop => e => {
    let value = e.target.value

    if (type === 'money') {
      value = parseInt(value.replace(/[^0-9]/g, ''))
    }

    setState({
      ...state,
      [prop]: value
    })
  }

  return (
    <Fragment>
      <FormField>
        <Label color={gray} align='right'>De: </Label>
        <InputComponent tabIndex={0} type={type} value={state.gte} onChange={handleChange('gte')} onBlur={() => onChange(state)} />
      </FormField>
      <FormField>
        <Label color={gray} align='right'>Até: </Label>
        <InputComponent tabIndex={0} type={type} value={state.lte} onChange={handleChange('lte')} onBlur={() => onChange(state)} />
      </FormField>
    </Fragment>
  )
}

Range.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

Range.defaultProps = {
  value: {}
}

export { Range }
