import styled, { css } from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import { bigPadding } from 'src/Styles/settings/Constants'

const StyledCol = styled(Grid.Col)`
  padding: 1.5rem 0;

  &:first-child {
    padding-left: ${bigPadding};
  }

  ${({ active }) => active && css`
    background: ${({ theme: { color } }) => color};
  `}
`

export default StyledCol
