import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { parseDate } from 'src/Utils/Utils'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Title from 'src/UIComponents/Title/Title'

import { bigText, gray, darkGray, bigPadding } from 'src/Styles/settings/Constants'
import { SaleActions } from '../../../Components/SaleDetail/SaleActions'

const SaleInfoBoleto = ({ sale }) => {
  return (
    <Grid noPadding>
      <Grid.Row auto>
        <Title noTransform noPadding small line>
          <strong>Dados</strong> da Venda
        </Title>
      </Grid.Row>
      <ContentWrapper>
        {sale?.status === 'pending' && (
          <Grid.Row auto>
            <SaleActions sale={sale} isBoleto />
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Col cols={8}>
            <Grid.Row noMargin auto>
              <Label color={gray}>
                Vencimento do Boleto
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label
                color={darkGray}
                fontSize={bigText}
              >
                {parseDate(sale.payment_method.expiration_date, 'DD/MM/YYYY', '+0300')}
              </Label>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col cols={8}>
            <Grid.Row noMargin auto>
              <Label color={gray}>
                Número do Documento
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label
                color={darkGray}
                fontSize={bigText}
              >
                {sale.payment_method.document_number}
              </Label>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </ContentWrapper>
    </Grid>
  )
}

SaleInfoBoleto.propTypes = {
  sale: PropTypes.object
}

export default SaleInfoBoleto

const ContentWrapper = styled.div`
  padding-left: ${bigPadding};
  margin-bottom: ${bigPadding};
`
