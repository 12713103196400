import styled from 'styled-components'
import Label from 'src/UIComponents/Label/Label'

const StyledLabel = styled(Label)`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export default StyledLabel
