import React from 'react'
import ContentLoader from 'react-content-loader'

const HistoryLoader = (props) => (
  <ContentLoader
    speed={4}
    width={'100%'}
    height={220}
    viewBox='0 0 380 220'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    <rect x='20' y='0' rx='0' ry='0' width='336' height='39' />
    <circle cx='30' cy='80' r='11' />
    <rect x='45' y='70' rx='0' ry='0' width='119' height='18' />
    <rect x='180' y='70' rx='0' ry='0' width='86' height='18' />
    <rect x='280' y='70' rx='0' ry='0' width='62' height='18' />
    <circle cx='30' cy='115' r='11' />
    <rect x='45' y='105' rx='0' ry='0' width='119' height='18' />
    <rect x='180' y='105' rx='0' ry='0' width='86' height='18' />
    <rect x='280' y='105' rx='0' ry='0' width='62' height='18' />
    <circle cx='30' cy='150' r='11' />
    <rect x='45' y='140' rx='0' ry='0' width='119' height='18' />
    <rect x='180' y='140' rx='0' ry='0' width='86' height='18' />
    <rect x='280' y='140' rx='0' ry='0' width='62' height='18' />
  </ContentLoader>
)

export default HistoryLoader
