import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Title from 'src/UIComponents/Title/Title'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'

import { gray, darkGray, bigPadding } from 'src/Styles/settings/Constants'

const SaleInfoWallet = ({ sale }) => {
  return (
    <Grid noPadding>
      <Grid.Row auto>
        <Title noTransform noPadding small line>
          <strong>Dados</strong> da Venda
        </Title>
      </Grid.Row>
      <ContentWrapper>
        <Grid.Row noMargin auto>
          <Label color={gray}>
            Código de Autorização:
          </Label>
        </Grid.Row>
        <Grid.Row auto>
          {sale.transaction_number
            ? <LabelCopyToClipboard text={sale.transaction_number} color={darkGray} size='normal'>
              {sale.transaction_number}
            </LabelCopyToClipboard>
            : <Label color={darkGray}> Não encontrado</Label>
          }
        </Grid.Row>
      </ContentWrapper>
    </Grid>
  )
}

SaleInfoWallet.propTypes = {
  sale: PropTypes.oject
}

const ContentWrapper = styled.div`
  padding-left: ${bigPadding};
  margin-bottom: ${bigPadding};
`

export default SaleInfoWallet
