import styled, { css } from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import { lighterGray } from 'src/Styles/settings/Constants'

const StyledRow = styled(Grid.Row)`
  ${({ active }) => active && css`
    background-color: ${lighterGray};
  `}
`

export default StyledRow
