import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import ActivateButton from 'src/UIComponents/RecipientMeanListItem/Common/ActivateButton'
import DeleteButton from 'src/UIComponents/RecipientMeanListItem/Common/DeleteButton'
import ButtonsWrapper from 'src/UIComponents/RecipientMeanListItem/Common/ButtonsWrapper'
import StyledRow from 'src/UIComponents/RecipientMeanListItem/Common/StyledRow'
import StyledCol from 'src/UIComponents/RecipientMeanListItem/Common/StyledCol'
import TableEditCell from 'src/UIComponents/TableEditCell/TableEditCell'

import {
  darkGray,
  normalText,
  smallerText
} from 'src/Styles/settings/Constants'
import StyledLabel from 'src/UIComponents/RecipientMeanListItem/Common/StyledLabel'
import Dots from 'src/UIComponents/Dots/Dots'

const BankAccountListItem = ({
  bankAccount: {
    id,
    bank_name: bankName,
    bank_code: bankCode,
    type,
    routing_number: routingNumber,
    routing_check_digit: routingCheckDigit,
    account_number: accountNumber
  },
  submitDialogActive,
  deleteDialogActive,
  onOpenSubmitDialog,
  onOpenDeleteDialog,
  onSubmit,
  onDelete,
  onCloseDialog,
  disableActions,
  isLoading,
  hasActions,
  ...props
}) => (
  <StyledRow noMargin active={submitDialogActive || deleteDialogActive}>
    <StyledCol cols={!hasActions ? 5 : 6}>
      <Grid.Row auto noMargin>
        <Label fontSize={smallerText} color={darkGray}>
          Banco
        </Label>
      </Grid.Row>
      <Grid.Row auto>
        <StyledLabel fontSize={normalText} color={darkGray} bold>
          {`${bankCode} - ${bankName}`}
        </StyledLabel>
      </Grid.Row>
    </StyledCol>
    <StyledCol cols={hasActions ? 5 : 11}>
      <Grid.Row>
        <Grid.Col cols={3}>
          <Grid.Row auto noMargin>
            <Label fontSize={smallerText} color={darkGray}>
              Tipo de Conta
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label fontSize={normalText} color={darkGray} bold>
              {type === 'Checking' ? 'Corrente' : 'Poupança'}
            </Label>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col cols={3}>
          <Grid.Row auto noMargin>
            <Label fontSize={smallerText} color={darkGray}>
              Agência
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label fontSize={normalText} color={darkGray} bold>
              {routingNumber}
            </Label>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col cols={2}>
          <Grid.Row auto noMargin>
            <Label fontSize={smallerText} color={darkGray}>
              Dígito
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label fontSize={normalText} color={darkGray} bold>
              {routingCheckDigit}
            </Label>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col cols={3}>
          <Grid.Row auto noMargin>
            <Label fontSize={smallerText} color={darkGray}>
              Conta
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label fontSize={normalText} color={darkGray} bold>
              {accountNumber}
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </StyledCol>
    {hasActions && <StyledCol cols={(submitDialogActive || deleteDialogActive) && !isLoading ? 1 : 2} />}
    {
      hasActions && !isLoading && (
        <StyledCol
          cols={submitDialogActive || deleteDialogActive ? 4 : 3}
          active={submitDialogActive || deleteDialogActive}>
          {
            !submitDialogActive && !deleteDialogActive && (
              <ButtonsWrapper>
                <DeleteButton
                  data-tracking={`${props['data-tracking']} > Conta bancaria > Remover`}
                  data-test='config-meiosRecebimento-delete_contaBancaria-btn'
                  disabled={disableActions}
                  onClick={() => {
                    onOpenDeleteDialog(id)
                  }}
                />
                <ActivateButton
                  data-tracking={`${props['data-tracking']} > Conta bancaria > Ativar`}
                  data-test='config-meiosRecebimento-ativar_contaBancaria-btn'
                  disabled={disableActions}
                  onClick={() => {
                    onOpenSubmitDialog(id)
                  }}
                />
              </ButtonsWrapper>
            )
          }
          {
            submitDialogActive && (
              <TableEditCell
                action={() => {
                  onSubmit(id)
                }}
                close={onCloseDialog}
                data-tracking={`${props['data-tracking']} > Conta bancaria > Ativar > Enviar`}
                withAdjustPadding
              >
                Deseja <strong>ativar</strong> esta conta?
              </TableEditCell>
            )
          }
          {
            deleteDialogActive && (
              <TableEditCell
                action={() => {
                  onDelete(id)
                }}
                close={onCloseDialog}
                data-tracking={`${props['data-tracking']} > Conta bancaria > Remover > Enviar`}
                withAdjustPadding
              >
                Deseja <strong>excluir</strong> esta conta?
              </TableEditCell>
            )
          }
        </StyledCol>
      )
    }
    {
      isLoading && (
        <StyledCol
          cols={3}>
          <Container>
            <Dots outline />
          </Container>
        </StyledCol>
      )
    }
  </StyledRow>
)

BankAccountListItem.propTypes = {
  bankAccount: PropTypes.object,
  submitDialogActive: PropTypes.bool,
  hasActions: PropTypes.bool,
  deleteDialogActive: PropTypes.bool,
  onOpenSubmitDialog: PropTypes.func,
  onOpenDeleteDialog: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onCloseDialog: PropTypes.func,
  isLoading: PropTypes.bool,
  disableActions: PropTypes.bool,
  'data-tracking': PropTypes.string
}
const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export default BankAccountListItem
