import React from 'react'
import styled from 'styled-components'

import Dots from 'src/UIComponents/Dots/Dots'

import { lightGray, darkGray, darkerGray, smallerText, smallPadding, textBold } from 'src/Styles/settings/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { controlShowReportModal } from '../../Actions/exports'

const ReportButtonNotifier = () => {
  const dispatch = useDispatch()

  const {
    watchFiles,
    exportsList,
    errorList,
    showReportModal
  } = useSelector(({ exports }) => exports)

  if (showReportModal && (watchFiles.length <= 0 || exportsList.length <= 0 || errorList.length <= 0)) {
    return <></>
  }

  return (
    <Wrapper>
      <Button
        data-tracking={`Report > Open Notification`}
        data-test='report-btn-notifier'
        onClick={() => dispatch(controlShowReportModal(true))}
      >
        { watchFiles.length > 0
          ? (<>Você tem <strong>{watchFiles.length === 1 ? `${watchFiles.length} arquivo` : `${watchFiles.length} arquivos`}</strong> processando</>)
          : errorList.length > 0
            ? (<>Você tem <strong>{errorList.length === 1 ? `${errorList.length} arquivo que precisa` : `${errorList.length} arquivos que precisam`}</strong> da sua atenção!</>)
            : (<>Você tem <strong>{exportsList.length === 1 ? `${exportsList.length} arquivo exportado` : `${exportsList.length} arquivos exportados`}</strong></>)
        }
        {
          watchFiles.length > 0 && <Loading mini outline color={darkerGray} />
        }
      </Button>
    </Wrapper>
  )
}

const Loading = styled(Dots)`
  margin-left: 2rem;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Button = styled.button`
  width: auto;
  background-color: ${lightGray};
  border-radius: 5px;
  border: none;
  font-size: ${smallerText};
  line-height: 14px;
  color: ${darkGray};
  padding: 0 ${smallPadding};
  height: 30px;
  display: flex;
  white-space: nowrap;
  align-items: center;

  > strong {
    font-weight: ${textBold};
    margin: 0 0.3rem;
  }

  &:hover {
    background-color: #E5E5E5;
    transition: all ease-in-out 0.3s;
  }

  :focus {
    outline: none;
  }
`

ReportButtonNotifier.propTypes = {}

export default ReportButtonNotifier
