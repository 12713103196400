import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { normalText, darkGray, white, bigPadding } from 'src/Styles/settings/Constants'
import { red } from '../../Styles/settings/Constants'

const RadioForm = ({ id, value, checked, label, onChange, ...props }) => (
  <Container>
    <Radio type='radio' id={id} value={value} checked={checked} onChange={onChange} data-test={`radio-input-${value}`} {...props} />
    <label htmlFor={id}>{label}</label>
  </Container>
)

RadioForm.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 3rem;
`

const Radio = styled.input`
  :checked, :not(:checked) {
    position: absolute;
    left: -9999px;
  }

  :checked + label, :not(:checked) + label {
    position: relative;
    padding-left: ${bigPadding};
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: ${darkGray};
    font-size: ${normalText};
  }

  :checked + label:before, :not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid ${props => props.hasError ? red : props.theme.color};
    border-radius: 100%;
    background: ${white};
  }

  :checked + label:after, :not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: ${props => props.theme.color};
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  :not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  :checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`

export default RadioForm
