import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { formatAddress } from 'src/Utils/Utils'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Title from 'src/UIComponents/Title/Title'

import { smallerText, normalText, darkGray, darkerGray, bigPadding } from 'src/Styles/settings/Constants'

const SaleInfoBuyer = ({
  buyer
}) => {
  let buyerInfo = {
    name: `${buyer.first_name} ${buyer.last_name}`,
    address: formatAddress(buyer.address)
  }

  return (
    <Grid noPadding>
      <br />
      <Grid.Row auto>
        <Title noTransform noPadding small line>
          <strong>Dados</strong> do Comprador
        </Title>
      </Grid.Row>
      <ContentWrapper>
        <Grid.Row>
          <Grid.Col cols={9}>
            <Grid.Row noMargin auto>
              <Label fontSize={smallerText} color={darkGray}>
                Nome do Comprador
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label
                overflow
                color={darkerGray}
                fontSize={normalText}
              >
                {buyerInfo.name}
              </Label>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row auto noMargin>
          <Label fontSize={smallerText} color={darkGray}>
            Endereço:
          </Label>
        </Grid.Row>
        <Grid.Row auto>
          <Label
            color={darkerGray}
            fontSize={normalText}
          >
            {buyerInfo.address}
          </Label>
        </Grid.Row>
      </ContentWrapper>
      <br />
    </Grid>
  )
}

SaleInfoBuyer.propTypes = {
  buyer: PropTypes.object
}

export default SaleInfoBuyer

const ContentWrapper = styled.div`
  padding-left: ${bigPadding};
`
