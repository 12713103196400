import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import IconButton from 'src/UIComponents/IconButton/IconButton'
import { smallMargin } from 'src/Styles/settings/Constants'

const DeleteButton = ({
  onClick,
  hidden,
  ...props
}) => <StyledIconButton hide={hidden ? 1 : 0} outline {...props} onClick={onClick} src='/assets/icons/actions/delete.svg' />

const StyledIconButton = styled(IconButton)`
  margin-right: ${smallMargin};
  ${({ hide }) => hide && css`
    visibility: hidden;
  `}
`

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  hidden: PropTypes.bool
}

export default DeleteButton
