import React from 'react'
import styled from 'styled-components'

import { toMoney, parseDate, getPaymentType } from 'src/Utils/Utils'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'

import {
  margin,
  normalText,
  smallerText,
  biggerText,
  biggestText,
  gray,
  darkGray
} from 'src/Styles/settings/Constants'

const Receipt = ({ receipt, sale, logo }) => {
  return (
    receipt && (
      <UIReceipt>
        <Grid noPadding>
          <Grid.Row>
            <Grid.Col offset={2} cols={12}>
              <TicketInfos>
                <StyledGrid>
                  <Grid.Row auto>
                    <Logo src={logo} />
                  </Grid.Row>
                  {receipt.card && receipt.card.card_brand && (
                    <Grid.Row auto>
                      <Label
                        fontSize={normalText}
                        color={darkGray}
                        textAlign='center'
                      >
                        {receipt.card.card_brand} - Via Cliente
                      </Label>
                    </Grid.Row>
                  )}
                  {receipt.business_name && (
                    <Grid.Row auto noMargin>
                      <Label fontSize={biggerText} bold>
                        {receipt.business_name}
                      </Label>
                    </Grid.Row>
                  )}
                  {receipt.taxpayer_id && (
                    <Grid.Row auto>
                      <Label color={darkGray}>
                        {receipt.taxpayer_id?.toDocument()}
                      </Label>
                    </Grid.Row>
                  )}
                  <Grid.Row auto>
                    {receipt.auth_number && (
                      <Grid.Col>
                        <Grid.Row auto noMargin>
                          <Label color={gray} fontSize={smallerText}>
                            Auto
                          </Label>
                        </Grid.Row>
                        <Grid.Row auto noMargin>
                          <Label bold uppercase>
                            {receipt.auth_number}
                          </Label>
                        </Grid.Row>
                      </Grid.Col>
                    )}
                    {receipt.receipt_number && (
                      <Grid.Col>
                        <Grid.Row auto noMargin>
                          <Label color={gray} fontSize={smallerText}>
                            CV
                          </Label>
                        </Grid.Row>
                        <Grid.Row auto noMargin>
                          <Label bold uppercase>
                            {receipt.receipt_number}
                          </Label>
                        </Grid.Row>
                      </Grid.Col>
                    )}
                  </Grid.Row>
                  {receipt.business_address &&
                    receipt.business_address.line1 &&
                    receipt.business_address.state && (
                      <>
                        <Grid.Row auto noMargin>
                          <Label color={gray} fontSize={smallerText}>
                            Endereço
                          </Label>
                        </Grid.Row>
                        <Grid.Row auto>
                          <Label bold>
                            {receipt.business_address.line1} -{' '}
                            {receipt.business_address.state}
                          </Label>
                        </Grid.Row>
                      </>
                    )}
                  <br />
                  <br />
                  <br />
                  <br />
                  <Grid.Row auto noMargin>
                    <Label color={gray} fontSize={smallerText}>
                      Valor Aprovado
                    </Label>
                  </Grid.Row>
                  <Grid.Row auto>
                    <Label bold fontSize={biggestText}>
                      {toMoney(receipt.amount)}
                    </Label>
                  </Grid.Row>
                  <Grid.Row auto>
                    <Grid.Col>
                      <Grid.Row auto noMargin>
                        <Label color={gray} fontSize={smallerText}>
                          Data da Venda
                        </Label>
                      </Grid.Row>
                      <Grid.Row auto noMargin>
                        <Label bold uppercase>
                          {parseDate(receipt.created_at, 'DD/MM/YYYY HH:mm')}
                        </Label>
                      </Grid.Row>
                    </Grid.Col>
                    {receipt.card && receipt.card.first4_digits && (
                      <>
                        <Grid.Col>
                          <Grid.Row auto noMargin>
                            <Label color={gray} fontSize={smallerText}>
                              Primeiros dígitos do Cartão
                            </Label>
                          </Grid.Row>
                          <Grid.Row auto noMargin>
                            <Label bold uppercase>
                              {receipt.card.first4_digits} **** **** ****
                            </Label>
                          </Grid.Row>
                        </Grid.Col>
                      </>
                    )}
                  </Grid.Row>
                  {sale.payment_type && sale.installment_plan && (
                    <>
                      <Grid.Row noMargin auto>
                        <Label color={gray} fontSize={smallerText}>
                          Tipo de Pagamento
                        </Label>
                      </Grid.Row>
                      <Grid.Row auto>
                        <Label bold>
                          {getPaymentType(
                            sale.payment_type,
                            sale.installment_plan
                          )}
                        </Label>
                      </Grid.Row>
                      <Grid.Row auto>
                        <Label
                          color={gray}
                          fontSize={'8.5px'}
                          textAlign='center'
                        >
                          Caso não se sinta satisfeito após atendimento, fale
                          com a <br /> Ouvidoria: 0800 555 0045 |
                          ouvidoria@zoop.com.br
                        </Label>
                      </Grid.Row>
                    </>
                  )}
                </StyledGrid>
                <br />
                <br />
              </TicketInfos>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </UIReceipt>
    )
  )
}

const UIReceipt = styled.div`
  color: ${gray};
  margin-bottom: ${margin};
`

const TicketInfos = styled.div`
  background-image: url('/assets/imgs/ticket.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const Logo = styled.img`
  max-height: 42px;
  max-width: 112px;
  margin: auto;
  margin-top: ${margin};
`

const StyledGrid = styled(Grid)`
  padding: 0 2.5rem;
`

export default Receipt
