import styled from 'styled-components'
import { normalPadding } from 'src/Styles/settings/Constants'

const ButtonsWrapper = styled.div`
  padding: 0 ${normalPadding};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export default ButtonsWrapper
