/* eslint react/prop-types: 0 */
import {
  bigText,
  darkGray,
  gray,
  green,
  textBold,
  yellow
} from 'src/Styles/settings/Constants'
import { parseDate, toMoney } from 'src/Utils/Utils'

import Card from 'src/UIComponents/Card/Card'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import React from 'react'
import Table from 'src/Components/Table/NewTable'
import moment from 'moment'
import { statusLabels } from 'src/Constants/StatusLabels'
import styled from 'styled-components'

const Payment = ({ sale, receivables }) => {
  const columns = [
    {
      Header: 'Data do pagamento',
      accessor: 'expected_on',
      width: 90,
      Cell: ({ row }) => {
        const { paid_at: paidAt, expected_on: expectedOn } = row.original
        let date = paidAt ? moment(paidAt) : moment(expectedOn).utc()

        date = date.format('DD/MM/YYYY')

        return <Label color={darkGray}>{date}</Label>
      },
      sortable: false
    },
    {
      Header: 'Parcela',
      accessor: 'installment',
      width: 60,
      Cell: ({ cell }) => {
        const value = cell.value || 1
        const numberInstallments =
          (sale &&
            sale.installment_plan &&
            sale.installment_plan.number_installments) ||
          1
        return (
          <Label color={darkGray}>{`${value}/${numberInstallments}`}</Label>
        )
      },
      sortable: false
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ cell }) => (
        <Label uppercase bold overflow color={statusLabels[cell.value].color}>
          {statusLabels[cell.value].label}
        </Label>
      ),
      sortable: false
    },
    {
      Header: 'Valor Líquido',
      accessor: 'amount',
      Cell: ({ row, cell }) => {
        const value = cell?.value?.replace(',', '.') || 0
        const anticipation_fee =
          row.original?.anticipation_fee.replace(',', '.') || 0
        return (
          <Label color={darkGray}>{toMoney(value - anticipation_fee)}</Label>
        )
      },
      sortable: false
    }
  ]

  const getPendingAmount = () => {
    return receivables.reduce((acc, receivable) => {
      if (receivable.status === 'pending') {
        const amount = receivable.amount.replace(',', '.') || 0
        const anticipation_fee =
          receivable.anticipation_fee.replace(',', '.') || 0
        acc += parseFloat(amount - anticipation_fee)
        return acc
      }
      return acc
    }, 0)
  }

  const getPaidAmount = () => {
    return receivables.reduce((acc, receivable) => {
      if (receivable.paid_at) {
        const amount = receivable.amount.replace(',', '.') || 0
        const anticipation_fee =
          receivable.anticipation_fee.replace(',', '.') || 0
        acc += parseFloat(amount - anticipation_fee)
        return acc
      }
      return acc
    }, 0)
  }

  const hasReceivables = receivables && receivables.length > 0

  return (
    receivables && (
      <UIPayment>
        <Grid noPadding>
          <Grid.Row>
            <Grid.Col cols={7} offset={1}>
              <Card withShadow>
                <BlockLabel
                  uppercase
                  color={gray}
                  textAlign='center'
                  data-test='transaction-detailSale-label-payout'
                >
                  Valor <Bold>pago</Bold>
                </BlockLabel>
                <Label
                  bold
                  fontSize={bigText}
                  color={green}
                  data-test='transaction-detailSale-label-value'
                >
                  {toMoney(getPaidAmount())}
                </Label>
              </Card>
            </Grid.Col>
            <Grid.Col cols={7}>
              <Card withShadow>
                <Grid.Row noMargin auto>
                  {hasReceivables ? (
                    <BlockLabel
                      uppercase
                      color={gray}
                      textAlign='center'
                      data-test='transaction-detailSale-label-pendent'
                    >
                      Valor <Bold>pendente</Bold>
                    </BlockLabel>
                  ) : (
                    <BlockLabel
                      uppercase
                      color={gray}
                      textAlign='center'
                      data-test='transaction-detailSale-label-datePayout'
                    >
                      Data <Bold>pagamento</Bold>
                    </BlockLabel>
                  )}
                </Grid.Row>
                <Grid.Row noMargin auto>
                  <Label
                    bold
                    fontSize={bigText}
                    color={hasReceivables ? yellow : darkGray}
                    data-test='transaction-detailSale-label-date'
                  >
                    {hasReceivables
                      ? toMoney(getPendingAmount())
                      : parseDate(sale.created_at, 'DD/MM/YYYY')}
                  </Label>
                </Grid.Row>
              </Card>
            </Grid.Col>
          </Grid.Row>
          {hasReceivables && (
            <Grid.Row>
              <Grid.Col cols={14} offset={1}>
                <Table
                  tableColumns={columns}
                  showBottom={false}
                  tableData={receivables.sort(
                    (a, b) => a.installment - b.installment
                  )}
                  manual
                  iconComponent={false}
                  chessBackground={false}
                  showPagination={false}
                  mini
                />
              </Grid.Col>
            </Grid.Row>
          )}
        </Grid>
      </UIPayment>
    )
  )
}

const UIPayment = styled.div`
  color: ${gray};
`

const Bold = styled.span`
  font-weight: ${textBold};
`

const BlockLabel = styled(Label)`
  display: block;
`

export default Payment
