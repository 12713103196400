import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Title from 'src/UIComponents/Title/Title'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'

import { smallerText, normalText, darkGray, darkerGray, bigPadding, spaceMargin } from 'src/Styles/settings/Constants'
import { getCardBrandImg } from 'src/Utils/Utils'
import { getEntryMode } from '../../../Constants/EntryModesDict'

const SaleInfoCard = ({ sale }) => {
  return (
    <Grid noPadding>
      <Grid.Row auto>
        <Title noTransform noPadding small line>
          <strong>Dados</strong> da Venda
        </Title>
      </Grid.Row>
      <ContentWrapper>
        <Grid.Row>
          <Grid.Col cols={11}>
            <Grid.Row auto noMargin>
              <Label fontSize={smallerText} color={darkGray}>
                Portador do Cartão:
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label
                color={darkerGray}
                fontSize={normalText}
              >
                {sale.payment_method.holder_name}
              </Label>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col cols={5}>
            <Grid.Row noMargin auto>
              <Label fontSize={smallerText} color={darkGray}>
                Modo de Captura
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label
                color={darkerGray}
                fontSize={normalText}
              >
                {getEntryMode(sale.point_of_sale.entry_mode)}
              </Label>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col cols={11}>
            <Grid.Row noMargin auto>
              <Label fontSize={smallerText} color={darkGray}>
                Número do cartão
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <StyledLabel
                color={darkerGray}
                fontSize={normalText}
              >
                <Img src={getCardBrandImg(sale.payment_method.card_brand)} />{` ${sale.payment_method.first4_digits || '****'} **** **** ${sale.payment_method.last4_digits || '****'}`}
              </StyledLabel>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col cols={5}>
            <Grid.Row noMargin auto>
              <Label fontSize={smallerText} color={darkGray}>
                Validade do cartão
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label
                color={darkerGray}
                fontSize={normalText}
              >
                {`${`0${sale.payment_method.expiration_month}`.slice(-2)}/${sale.payment_method.expiration_year}`}
              </Label>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row auto noMargin>
          <Label fontSize={smallerText} color={darkGray}>
            Código de Autorização:
          </Label>
        </Grid.Row>
        <Grid.Row auto>
          {sale.transaction_number
            ? <LabelCopyToClipboard
              color={darkerGray}
              size='normal'
              text={sale.transaction_number}
            >
              {sale.transaction_number}
            </LabelCopyToClipboard>
            : <Label color={darkerGray}>N/A</Label>
          }
        </Grid.Row>
        <br />
      </ContentWrapper>
      <br />
    </Grid>
  )
}

SaleInfoCard.propTypes = {
  sale: PropTypes.object
}

export default SaleInfoCard

const ContentWrapper = styled.div`
  padding-left: ${bigPadding};
`
const Img = styled.img`
  margin-right: ${spaceMargin};
`
const StyledLabel = styled(Label)`
  align-items: center;
`
