import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import Grid from '../Grid/Grid'
import Title from '../Title/Title'

import { green, gray, darkGray, normalText, textBold, margin, bigMargin, normalPadding } from '../../Styles/settings/Constants'

const check = '/assets/icons/system/check.svg'
const uncheck = '/assets/icons/system/dash.svg'

const ProfilesDetails = ({ profile }) => (
  <Container>
    <Grid.Col offset={1} cols={15}>
      <Grid.Row auto>
        <Text>{profile.description}</Text>
      </Grid.Row>
      <Header auto>
        <Title noTransform small line noPadding>
          <strong>Área</strong> Operacional
        </Title>
      </Header>
      <Grid.Row>
        <Col offset={8} cols={4}>
          <TextCol>Visualização</TextCol>
        </Col>
        <Col cols={4}>
          <TextCol>Ação</TextCol>
        </Col>
      </Grid.Row>
      {Object.entries(profile.operational).map(([key, value]) => (
        <Row key={key}>
          <Grid.Col cols={8}>
            <TextCell>{value.label}</TextCell>
          </Grid.Col>
          <Grid.Col cols={4}>
            <Icon check={value.visualization} src={value.visualization ? check : uncheck} />
          </Grid.Col>
          <Grid.Col cols={4}>
            <Icon check={value.action} src={value.action ? check : uncheck} />
          </Grid.Col>
        </Row>
      ))}

      <Header auto>
        <Title noTransform small line noPadding>
          <strong>Área</strong> de Configurações
        </Title>
      </Header>
      <Grid.Row>
        <Col offset={8} cols={4}>
          <TextCol>Visualização</TextCol>
        </Col>
        <Col cols={4}>
          <TextCol>Ação</TextCol>
        </Col>
      </Grid.Row>
      {Object.entries(profile.configuration).map(([key, value]) => (
        <Row key={key}>
          <Grid.Col cols={8}>
            <TextCell>{value.label}</TextCell>
          </Grid.Col>
          <Grid.Col cols={4}>
            <Icon check={value.visualization} src={value.visualization ? check : uncheck} />
          </Grid.Col>
          <Grid.Col cols={4}>
            <Icon check={value.action} src={value.action ? check : uncheck} />
          </Grid.Col>
        </Row>
      ))}
    </Grid.Col>
  </Container>
)

ProfilesDetails.propTypes = {
  profile: PropTypes.object
}

const Container = styled(Grid.Row)`
  margin-bottom: ${bigMargin} !important;
`

const Row = styled(Grid.Row)`
  margin-bottom: ${margin};
`

const Text = styled.p`
  color: ${gray};
  font-size: ${normalText};
`

const Header = styled(Grid.Row)`
  margin-top: ${bigMargin};
`

const Col = styled(Grid.Col)`
  text-align: center;
`

const TextCol = styled.span`
  color: ${darkGray};
  font-size: ${normalText};
  text-align: center;
`

const TextCell = styled.span`
  color: ${darkGray};
  font-size: ${normalText};
  font-weight: ${textBold};
  float: right;
  padding-right: ${normalPadding};
  text-align: right;
`

const Icon = styled(ReactSVG)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${({ check }) => check && css`
    svg {
      fill: ${green}
    }
  `}
`

export default ProfilesDetails
